.work__filters {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.75rem;
  margin-bottom: var(--mb-2);
}

.work__item {
  color: var(--title-color);
padding: 0.25rem 0.75rem;
font-weight: var(--font-medium);
border-radius: 0.5rem;
text-transform: capitalize;
}

.work__container {
  grid-template-columns: repeat(2, max-content);
  gap: 3rem;
  justify-content: center;
}

.work__item:hover {
  background-color: var(--title-color);
  color: var(--container-color);
  cursor: pointer;
}

.work__card {
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(0,0,0,0.1);
  padding: 1.25rem;
  border-radius: 1rem;
}

.work__description {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.work__card:hover .work__description {
  position: absolute;
  top: 40%;
  left: 40%;
  transform: translate(-50%, -50%);
  color: white;
  opacity: 1;
  transition: opacity 0.3s ease;
  z-index: 2;
}

.work__img {
  width: 295px;
  border-radius: 1rem;
  margin-bottom: var(--mb-1)
}

.work__title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-0-5);
}

.work__button {
  color: #fff;
  position: absolute;
  bottom: 1.5rem;
  left: 1.5rem;
  height: 40px;
  width: 40px;
  background-color: var(--color-yellow);
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  cursor: pointer;
  font-size: var(--h3-font-size);
  display: block;
  align-items: center;
  opacity: 0;
  transition: 0.3s;
  z-index: 100;
}

.work__button-icon {
  font-size: 1rem;
  transition: 0.4s;
}

.work__button:hover .work__button-icon {
  transform: translateX(0.25rem);
}

.active-work {
  background-color: var(--title-color);
  color: var(--container-color);
}

.work__mask {
  position: absolute;
  background: black;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  transition: 0.3s;
  opacity: 0;
}

.work__card:hover .work__mask {
  opacity: 0.8;
}

.work__category {
  color: #fff;
  background-color: #e55c4f;
  border-bottom-left-radius: 0.98rem;
  border-bottom-right-radius: 0.98rem;
  position: absolute;
  top: 0;
  left: 1.5rem;
  font-size: var(--small-font-size);
  display: inline-block;
  padding: 0.19rem 0.625rem;
  transform: translateY(-40px);
  transition: 0.3s;
}

.work__card:hover .work__button {
  opacity: 1;
}

.work__card:hover .work__category {
  opacity: 1;
  transform: translateY(0);
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .work__container {
        gap: 1.25rem;
    }

    .work__card {
        padding: 1rem;
    }

    .work__img {
        margin-bottom: 0.75rem;
    }

    .work__title {
        margin-bottom: 0.25rem;
    }
  }
  
  /* For medium devices */
  @media screen and (max-width: 768px) {
    .work__container {
        grid-template-columns: max-content;
    }
  }
  
  @media screen and (max-width: 576px) {
    .work__container {
        grid-template-columns: 1fr;
    }

    .work__img {
        width: 100%;
    }
    .work__filters {
        display: grid;
        grid-template-columns: repeat(3,max-content);
        align-items: center;
        column-gap: 0.25rem;
        margin-bottom: var(--mb-2);
        justify-content: center;
  }
  }
  
  /* For small devices */
  @media screen and (max-width: 350px) {
    .work__item {
        font-size: var(--small-font-size);
    }
}